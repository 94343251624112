<template>
  <!-- 数字通证审核 -->
  <div>
    <!-- 顶部选择框 -->
    <div style="padding: 30px">
      <el-radio-group v-model="tabls">
        <!-- <el-radio-button :label="true">转入数字通证审核</el-radio-button> -->
        <el-radio-button :label="false">转出数字通证记录页</el-radio-button>
      </el-radio-group>
    </div>
    <!-- 顶部查询 -->
    <div class="form">
      <!-- 按时间查询 -->
      <div>
        <span>按时间查询:</span>
        <div>
          <el-date-picker
            v-model="form.time1"
            type="date"
            value-format="yyyy-MM-dd 00:00:00"
            placeholder="开始日期"
            style="width: 210px"
            clearable
          >
          </el-date-picker
          >-
          <el-date-picker
            v-model="form.time2"
            type="date"
            value-format="yyyy-MM-dd 00:00:00"
            placeholder="结束日期"
            style="width: 210px"
            clearable
          >
          </el-date-picker>
        </div>
      </div>

      <div>
        <span>按转出用户账号</span>
        <div>
          <el-input v-model="form.fromId" placeholder="请输入转出用户账号" style="width: 200px" clearable></el-input>
        </div>
      </div>

      <div>
        <span>按接收方用户UID</span>
        <div>
          <el-input v-model="form.userId" placeholder="请输入接收方用户UID" style="width: 200px" clearable></el-input>
        </div>
      </div>

      <!-- 输入地址查询 -->
      <div>
        <span>按接收方地址查询</span>
        <div>
          <el-input v-model="form.turnsOutAddress" placeholder="请输入接收方地址" style="width: 200px" clearable></el-input>
        </div>
      </div>
      <!-- 按数字通证名称查询按数字通证名称查询 -->
      <!-- <div v-show="tabls">
        <span>按数字通证名称查询</span>
        <div>
          <el-select v-model="value" placeholder="请选择" style="width: 200px">
            <el-option
              v-for="item in options"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
        </div>
      </div> -->
      <!-- 按审核状态查询 -->
      <!-- <div>
        <span>按审核状态查询</span>
        <div>
          <el-select v-model="form.type" placeholder="请选择" style="width: 200px">
            <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value" clearable> </el-option>
          </el-select>
        </div>
      </div> -->
      <!-- 输入手机号码查询 -->
      <!-- <div>
        <span>按手机号查</span>
        <div>
          <el-input
            v-model="form.phone"
            placeholder="请输入手机号"
            prefix-icon="el-icon-mobile-phone"
            style="width: 140px"
            clearable
          ></el-input>
        </div>
      </div> -->
      <el-button type="primary" icon="el-icon-search" style="height: 40px; margin-top: 40px" @click="doFilter">搜索</el-button>
      <el-button type="primary" icon="el-icon-search" style="height: 40px; margin-top: 40px" @click="noFilter">重置</el-button>
      <el-button type="primary" icon="el-icon-search" style="height: 40px; margin-top: 40px" @click="exportData">导出表格</el-button>
    </div>

    <!-- 提数字通证审核 -->
    <div v-show="tabls">
      <!-- 批量勾选 -->
      <!-- <div>
        <span style="margin-right: 20px">批量操作(请勾选表格):</span>
        <el-button type="success" size="mini" :disabled="disable"
          >通过</el-button
        >
        <el-button type="danger" size="mini" :disabled="disable"
          >不通过</el-button
        >
        <span class="text"
          >提数字通证总数<span>{{ Tnum }}</span></span
        >
      </div> -->
      <!-- 表格 -->
      <div style="margin-top: 30px">
        <el-table ref="multipleTable" tooltip-effect="dark" style="width: 100%" border="" :data="Tdata">
          <!-- <el-table-column type="selection" width="55"> </el-table-column> -->
          <el-table-column label="记录Id" prop="id" width="120"> </el-table-column>
          <!-- <el-table-column label="手机号" prop="userTel" width="120"> </el-table-column> -->
          <el-table-column label="转出方账号" prop="fromId" width="120"> </el-table-column>
          <el-table-column label="接收方UID" prop="userId" width="120"> </el-table-column>
          <el-table-column label="接收方钱包地址" prop="wallet"> </el-table-column>
          <el-table-column label="通证类型" prop="tokenName"> </el-table-column>
          <!-- <el-table-column label="姓名" prop="userName"> </el-table-column> -->
          <el-table-column label="数量" prop="num"> </el-table-column>
          <!-- <el-table-column label="数字通证地址"> </el-table-column> -->
          <el-table-column label="转入数字通证时间" prop="createTime"> </el-table-column>
          <!-- <el-table-column label="状态">
            <template slot-scope="scope">
              <div>
                {{
                  scope.row.status == 1
                    ? "通过"
                    : scope.row.status == 2
                    ? "未通过"
                    : "待审核"
                }}
              </div>
            </template>
          </el-table-column> -->
          <el-table-column label="手续费" prop="serverChange"> </el-table-column>
          <!-- <el-table-column label="操作">
            <template slot-scope="scope">
              <div v-show="scope.row.status == 0">
                <el-button size="mini" type="success" @click="handleEdit(scope.row.id, scope.$index)">通过</el-button>
                <el-button size="mini" type="danger" @click="handleDelete(scope.row.id, scope.$index)">不通过</el-button>
              </div>
            </template>
          </el-table-column> -->
        </el-table>
      </div>
    </div>

    <!-- 转出数字通证记录页 -->
    <div v-show="!tabls">
      <div>
        <!-- <span style="margin: 0 30px">转入数字通证总数</span>
        <span style="color: red">{{ Cnum }}</span> -->
      </div>
      <div style="margin-top: 30px">
        <el-table border :data="Cdata">
          <el-table-column prop="id" label="记录Id"></el-table-column>
          <!-- <el-table-column prop="userTel" label="手机号"></el-table-column> -->
          <el-table-column label="转出方账号" prop="fromId" width="120"> </el-table-column>
          <el-table-column label="接收方UID" prop="userId" width="120"> </el-table-column>
          <el-table-column label="接收方钱包地址" prop="wallet"> </el-table-column>
          <el-table-column label="通证类型" prop="tokenName"> </el-table-column>
          <!-- <el-table-column
            label="通证类型"
            prop="tokenName
"
          >
            <template slot-scope="scope">
              <span v-if="scope.row.certificateId == 29">乾锦通</span>
              <span v-if="scope.row.certificateId == 30">伏羲宝</span>
              <span v-if="scope.row.certificateId == 43">小蜜蜂</span>
              <span v-if="scope.row.certificateId == 44">泰极数证</span>
              <span v-if="scope.row.certificateId == 45">众惠宝</span>
              <span v-if="scope.row.certificateId == 46">心洲通</span>
              <span v-if="scope.row.certificateId == 48">农鲜通</span>
              <span v-if="scope.row.certificateId == 49">汇通宝</span>
              <span v-if="scope.row.certificateId == 51">融百业</span>
            </template>
          </el-table-column> -->
          <!-- <el-table-column prop="userName" label="姓名"></el-table-column> -->
          <el-table-column prop="num" label="数量"></el-table-column>
          <el-table-column prop="remark" label="备注"></el-table-column>
          <!-- <el-table-column prop="status" label="状态" width="80">
            <template slot-scope="scope">
              <div>
                {{
                  scope.row.status == 1
                    ? "通过"
                    : scope.row.status == 2
                    ? "未通过"
                    : "待审核"
                }}
              </div>
            </template>
          </el-table-column> -->
          <el-table-column prop="createTime" label="转出时间"></el-table-column>
          <!-- <el-table-column label="操作" width="180" align="center">
            <template slot-scope="scope">
              <div v-show="scope.row.status == 0">
                <el-button size="mini" type="success" @click="handleEdit(scope.row.id, scope.$index)">通过</el-button>
                <el-button size="mini" type="danger" @click="handleDelete(scope.row.id, scope.$index)">不通过</el-button>
              </div>
            </template>
          </el-table-column> -->
        </el-table>
      </div>
    </div>
    <!-- 底部分页 -->
    <div class="pagination">
      <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="currentPage"
        :page-sizes="[10, 15, 20, 30]"
        :page-size="pageNumber"
        layout="total, sizes, prev, pager, next, jumper"
        :total="total"
      >
      </el-pagination>
    </div>
  </div>
</template>

<script>
import { xmlUrl } from '../../utils/xmlurl'
export default {
  data() {
    return {
      disable: true, // 按钮状态
      tabls: false,
      Tnum: 0, // 提数字通证总数
      Cnum: 0, // 充数字通证总数
      form: {
        time1: '',
        time2: '',
        phone: '',
        userId: '',
        fromId: '',
        turnsOutAddress: '',
        type: ''
      },
      options: [
        {
          value: 0,
          label: '待审核'
        },
        {
          value: 1,
          label: '审核通过'
        },
        {
          value: 2,
          label: '审核未通过'
        }
      ],
      value: '',
      Tdata: null,
      Cdata: null,
      total: null,
      currentPage: 1,
      pageNumber: 20
    }
  },
  watch: {
    tabls(news, jiu) {
      if (news) {
        this.axios
          .get('/admin/tokenWithdraw/showTokenWithdraw', {
            type: 1
          })
          .then(res => {
            if (res.data.code == 200) {
              this.Cdata = res.data.data.records
              this.total = res.data.data.total
              this.currentPage = res.data.data.current
              this.pageNumber = 20
            }
          })
      } else {
        this.axios
          .get('/admin/tokenWithdraw/showTokenWithdraw', {
            type: 2
          })
          .then(res => {
            if (res.data.code == 200) {
              this.Cdata = res.data.data.records
              this.total = res.data.data.total
              this.currentPage = res.data.data.current
              this.pageNumber = 20
            }
          })
      }
    }
  },

  created() {
    this.axios
      .get('/admin/tokenWithdraw/showTokenWithdraw', {
        type: 2,
        currentPage: this.currentPage,
        pageSize: this.pageNumber
      })
      .then(res => {
        console.log(res, '22222222222')
        if (res.data.code == 200) {
          this.Cdata = res.data.data.records
          this.total = res.data.data.total
          this.currentPage = res.data.data.current
          this.pageNumber = 20
        }
      })
      .catch(err => {})

    // 排序
  },
  mounted() {},
  methods: {
    //   搜索
    doFilter() {
      if (this.tabls) {
        this.axios
          .get('/admin/tokenWithdraw/showTokenWithdraw', {
            type: 1,
            userTel: this.form.phone,
            status: this.form.type,
            fromId: this.form.fromId,
            userId: this.form.userId,
            wallet: this.form.turnsOutAddress,
            startTime: this.form.time1,
            endTime: this.form.time2,
            currentPage: this.currentPage,
            pageSize: this.pageNumber
          })
          .then(res => {
            console.log(res)
            if (res.data.code == 200) {
              this.Tdata = res.data.data.records
              this.total = res.data.data.total
              this.currentPage = res.data.data.current
              this.pageNumber = 20
            }
          })
      } else {
        this.axios
          .get('/admin/tokenWithdraw/showTokenWithdraw', {
            type: 2,
            userTel: this.form.phone,
            status: this.form.type,
            fromId: this.form.fromId,
            userId: this.form.userId,
            wallet: this.form.turnsOutAddress,
            startTime: this.form.time1,
            endTime: this.form.time2,
            currentPage: this.currentPage,
            pageSize: this.pageNumber
          })
          .then(res => {
            console.log(res)
            if (res.data.code == 200) {
              this.Cdata = res.data.data.records
              this.total = res.data.data.total
              this.currentPage = res.data.data.current
              this.pageNumber = 20
            }
          })
      }
    },
    // 导出表格
    exportData() {
      console.log('2222222')
      console.log(this.form)
      this.$message.success('导出中，请稍候');
      this.axios
        .get('/admin/tokenWithdraw/getAll', {
          type: 2,
          userTel: this.form.phone,
          status: this.form.type,
          fromId: this.form.fromId,
          userId: this.form.userId,
          wallet: this.form.turnsOutAddress,
          startTime: this.form.time1,
          endTime: this.form.time2
        })
        .then(res => {
          setTimeout(function(){
              window.open(xmlUrl + res.data.data)
          },5000);
        })

      // 注释这里的原因是后端说这个接口太耗资源，该用列表接口
      // this.axios.get('/admin/userWithdraw/withdrawExcel', {}).then(res => {
      // this.axios.get('/admin/tokenWithdraw/getAll', this.form).then(res => {
      //   console.log(res)
      //   if (res.data.code == 200) {
      //     // window.open(`http://47.109.88.30:9071/${res.data.data}`)
      //     window.open(xmlUrl + res.data.data)
      //   } else {
      //     console('2222')
      //   }
      // })
    },

    // 重置
    noFilter() {
      this.form.phone = ''
      this.form.type = ''
      this.form.fromId = ''
      this.form.userId = ''
      this.form.turnsOutAddress = ''
      this.form.time1 = ''
      this.form.time2 = ''
      this.doFilter()
    },

    // 通过
    handleEdit(id, index) {
      if (this.tabls) {
        this.axios
          .get('/admin/tokenWithdraw/updateStatus', {
            id,
            status: 1
          })
          .then(res => {
            if (res.data.code == 200) {
              this.$message({
                message: res.data.mesg,
                type: 'success'
              })
              this.Tdata[index].status = 1
              this.Tata[index].failedReason = ''
            } else {
              this.$message({
                message: res.data.mesg,
                type: 'error'
              })
            }
          })
      } else {
        this.axios
          .get('/admin/tokenWithdraw/updateStatus', {
            id,
            status: 1
          })
          .then(res => {
            if (res.data.code == 200) {
              this.$message({
                message: res.data.mesg,
                type: 'success'
              })
              this.Cdata[index].status = 1
              // this.Cata[index].failedReason = "";
            } else {
              this.$message({
                message: res.data.mesg,
                type: 'error'
              })
            }
          })
      }
    },

    // 不通过
    handleDelete(id, index) {
      if (this.tabls) {
        this.axios
          .get('/admin/tokenWithdraw/updateStatus', {
            // failedReason: value,
            id,
            status: 2
          })
          .then(res => {
            if (res.data.code == 200) {
              // this.Tdata[index].failedReason = value;
              this.Tdata[index].status = 2
              this.$message({
                type: 'success',
                message: res.data.mesg
              })
            } else {
              this.$message({
                type: 'error',
                message: res.data.mesg
              })
            }
          })
      } else {
        this.axios
          .get('/admin/tokenWithdraw/updateStatus', {
            // failedReason: value,
            id,
            status: 2
          })
          .then(res => {
            if (res.data.code == 200) {
              // this.Cdata[index].failedReason = value;
              this.Cdata[index].status = 2
              this.$message({
                type: 'success',
                message: res.data.mesg
              })
            } else {
              this.$message({
                type: 'error',
                message: res.data.mesg
              })
            }
          })
      }
    },
    // 分页
    handleSizeChange(val) {
      this.pageNumber = val
      if (this.tabls) {
        this.axios
          .get('/admin/tokenWithdraw/showTokenWithdraw', {
            type: 1,
            userTel: this.form.phone,
            status: this.form.type,
            fromId: this.form.fromId,
            userId: this.form.userId,
            wallet: this.form.turnsOutAddress,
            startTime: this.form.time1,
            endTime: this.form.time2,
            pageNumber: this.currentPage,
            pageSize: this.pageNumber
          })
          .then(res => {
            this.Tdata = res.data.data.records
          })
      } else {
        this.axios
          .get('/admin/tokenWithdraw/showTokenWithdraw', {
            type: 2,
            userTel: this.form.phone,
            status: this.form.type,
            fromId: this.form.fromId,
            userId: this.form.userId,
            wallet: this.form.turnsOutAddress,
            startTime: this.form.time1,
            endTime: this.form.time2,
            pageNumber: this.currentPage,
            pageSize: this.pageNumber
          })
          .then(res => {
            this.Cdata = res.data.data.records
          })
      }
    },
    handleCurrentChange(val) {
      this.currentPage = val
      if (this.tabls) {
        this.axios
          .get('/admin/tokenWithdraw/showTokenWithdraw', {
            type: 1,
            userTel: this.form.phone,
            status: this.form.type,
            fromId: this.form.fromId,
            userId: this.form.userId,
            wallet: this.form.turnsOutAddress,
            startTime: this.form.time1,
            endTime: this.form.time2,
            currentPage: this.currentPage,
            pageSize: this.pageNumber
          })
          .then(res => {
            this.Tdata = res.data.data.records
          })
      } else {
        this.axios
          .get('/admin/tokenWithdraw/showTokenWithdraw', {
            type: 2,
            userTel: this.form.phone,
            status: this.form.type,
            fromId: this.form.fromId,
            userId: this.form.userId,
            wallet: this.form.turnsOutAddress,
            startTime: this.form.time1,
            endTime: this.form.time2,
            currentPage: this.currentPage,
            pageSize: this.pageNumber
          })
          .then(res => {
            this.Cdata = res.data.data.records
          })
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.form {
  display: flex;
  margin-bottom: 30px;
  div {
    margin-right: 3px;
    span {
      //   font-weight: bold;
      font-size: 14px;
      display: inline-block;
      margin-bottom: 20px;
    }
  }
}
.pagination {
  padding: 60px 0 0 100px;
}
.text {
  color: #848484;
  margin: 0 20px;
  span {
    margin-left: 10px;
    color: red;
  }
}
</style>
